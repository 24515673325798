import { h } from 'preact';
import PropTypes from 'prop-types';
import { useState } from 'preact/hooks';
import { articlePropTypes } from '../../common-prop-types';
import { Button } from '../../crayons/Button';
import { locale } from '../../utilities/locale';

export const ReactionsCount = (data) => {

  const [article, setArticle] = useState(data.article);
  const [onClickDisabled, setOnClickDisabled] = useState(false);
  
  const authenticatedUser = document.body.dataset.user !== undefined;

  const totalReactions = article.public_reactions_count || 0;
  let baseClass = "crayons-reaction crayons-reaction--like crayons-tooltip__activator relative activated not-user-animated "
  if (article && article.user_reacted) {
    baseClass += "user-activated";
  } else {
    baseClass += "user-inactivated";
  }

  const onClick = async () => {
    if (onClickDisabled) { return }

    if (!authenticatedUser) {
      window.showLoginModal();
      return;
    }

    setOnClickDisabled(true)

    const response = await toggleArticleReaction(article.id)
    const new_reaction_count = (response.result == "destroy" ? totalReactions-1 : totalReactions+1)
    const new_user_reacted = (response.result != "destroy")
    setArticle({...article, public_reactions_count: new_reaction_count, user_reacted: new_user_reacted})

    setTimeout(() => {
      setOnClickDisabled(false)
    }, 250)
    
  }


  /**
   * Toggle reaction.
   *
   * @param {number} [page=1] Page of feed data to retrieve
   *
   * @returns {Promise} A promise containing the JSON response for the reaction data.
   */
  async function toggleArticleReaction(article_id) {
    const response = await fetch('/reactions', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': window.csrfToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ reactable_type: "Article", reactable_id: article_id, category: "like", authenticity_token: window.csrfToken }),
      credentials: 'same-origin',
    });
    return await response.json();
  }

  return (
    <button id="reaction-butt-like" aria-label="Upvote" aria-pressed="true" class={baseClass} data-category="like" onClick={onClick}>
      <span class="crayons-reaction__icon crayons-reaction__icon--inactive">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 26 24" fill="none" fill-opacity="0" class="crayons-icon reaction-icon not-reacted crayons-icon" role="img" aria-hidden="true">
          <path d="M11.4068 1.92387L1.24748 19.4718C1.08563 19.7522 1.00029 20.0701 1 20.3938C0.999714 20.7175 1.08449 21.0356 1.24584 21.3162C1.4072 21.5968 1.63945 21.8301 1.91935 21.9927C2.19925 22.1553 2.51696 22.2414 2.84065 22.2426H23.1593C23.483 22.2414 23.8008 22.1553 24.0806 21.9927C24.3605 21.8301 24.5928 21.5968 24.7542 21.3162C24.9155 21.0356 25.0003 20.7175 25 20.3938C24.9997 20.0701 24.9144 19.7522 24.7525 19.4718L14.5932 1.92387C14.4325 1.64318 14.2006 1.40991 13.9208 1.24768C13.6411 1.08544 13.3234 1 13 1C12.6766 1 12.3589 1.08544 12.0792 1.24768C11.7994 1.40991 11.5675 1.64318 11.4068 1.92387V1.92387Z" stroke="#10b981" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </span>
      <span class="crayons-reaction__icon crayons-reaction__icon--active">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 26 24" fill="none" class="crayons-icon reaction-icon--like reaction-icon reacted crayons-icon" role="img" aria-hidden="true">
          <path d="M11.4068 1.92387L1.24748 19.4718C1.08563 19.7522 1.00029 20.0701 1 20.3938C0.999714 20.7175 1.08449 21.0356 1.24584 21.3162C1.4072 21.5968 1.63945 21.8301 1.91935 21.9927C2.19925 22.1553 2.51696 22.2414 2.84065 22.2426H23.1593C23.483 22.2414 23.8008 22.1553 24.0806 21.9927C24.3605 21.8301 24.5928 21.5968 24.7542 21.3162C24.9155 21.0356 25.0003 20.7175 25 20.3938C24.9997 20.0701 24.9144 19.7522 24.7525 19.4718L14.5932 1.92387C14.4325 1.64318 14.2006 1.40991 13.9208 1.24768C13.6411 1.08544 13.3234 1 13 1C12.6766 1 12.3589 1.08544 12.0792 1.24768C11.7994 1.40991 11.5675 1.64318 11.4068 1.92387Z" fill="#5BB8B6" stroke="#2C5857" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </span>
      <span class="crayons-reaction__count" id="reaction-number-like">{totalReactions}</span>
      <span data-testid="tooltip" class="crayons-tooltip__content">
        Upvote
      </span>
    </button>
  )
}

export const ReactionsCountOld = ({ article, showText }) => {
  const totalReactions = article.public_reactions_count || 0;
  const reactionsSVG = () => (
    <svg width="24" height="24" viewBox="0 0 26 24" fill="none" fill-opacity="0" xmlns="http://www.w3.org/2000/svg" class="crayons-icon reaction-icon not-reacted crayons-icon">
      <path d="M11.4068 1.92387L1.24748 19.4718C1.08563 19.7522 1.00029 20.0701 1 20.3938C0.999714 20.7175 1.08449 21.0356 1.24584 21.3162C1.4072 21.5968 1.63945 21.8301 1.91935 21.9927C2.19925 22.1553 2.51696 22.2414 2.84065 22.2426H23.1593C23.483 22.2414 23.8008 22.1553 24.0806 21.9927C24.3605 21.8301 24.5928 21.5968 24.7542 21.3162C24.9155 21.0356 25.0003 20.7175 25 20.3938C24.9997 20.0701 24.9144 19.7522 24.7525 19.4718L14.5932 1.92387C14.4325 1.64318 14.2006 1.40991 13.9208 1.24768C13.6411 1.08544 13.3234 1 13 1C12.6766 1 12.3589 1.08544 12.0792 1.24768C11.7994 1.40991 11.5675 1.64318 11.4068 1.92387V1.92387Z" stroke="#10b981" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
  const reactionActiveSVG = () => (
  <span class="crayons-reaction__icon crayons-reaction__icon--active">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 26 24" fill="none" class="crayons-icon reaction-icon--like reaction-icon reacted crayons-icon" role="img" aria-hidden="true">
      <path d="M11.4068 1.92387L1.24748 19.4718C1.08563 19.7522 1.00029 20.0701 1 20.3938C0.999714 20.7175 1.08449 21.0356 1.24584 21.3162C1.4072 21.5968 1.63945 21.8301 1.91935 21.9927C2.19925 22.1553 2.51696 22.2414 2.84065 22.2426H23.1593C23.483 22.2414 23.8008 22.1553 24.0806 21.9927C24.3605 21.8301 24.5928 21.5968 24.7542 21.3162C24.9155 21.0356 25.0003 20.7175 25 20.3938C24.9997 20.0701 24.9144 19.7522 24.7525 19.4718L14.5932 1.92387C14.4325 1.64318 14.2006 1.40991 13.9208 1.24768C13.6411 1.08544 13.3234 1 13 1C12.6766 1 12.3589 1.08544 12.0792 1.24768C11.7994 1.40991 11.5675 1.64318 11.4068 1.92387Z" fill="#5BB8B6" stroke="#2C5857" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  </span>
  );

  return (
    <Button
      variant="ghost"
      size="s"
      contentType="icon-left"
      url={article.path}
      icon={article.user_reacted ? reactionActiveSVG : reactionsSVG}
      tagName="a"
      className="reaction-button crayons-btn-reaction crayons-reaction crayons-reaction--like crayons-tooltip__activator relative activated user-activated not-user-animated"
    >
      <span title="Number of reactions">
        {totalReactions}
        { showText ? (
          <span className="hidden s:inline">
            &nbsp;
            {`${totalReactions == 1 ? locale('core.reaction') : `${locale('core.reaction')}s`}`}
          </span>
        ) : null}
      </span>
    </Button>
  );
};

ReactionsCount.propTypes = {
  article: articlePropTypes.isRequired,
  showText: PropTypes.bool,
};

ReactionsCount.displayName = 'ReactionsCount';
